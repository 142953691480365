<!-- =========================================================================================
     File Name: categoryList.vue
    Description: Category
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->


<template>
  <div>
    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <!-- <h4 class="mb-0 text-uppercase">
            SUBCATEGORY LIST OF
            <span class="font-bold text-uppercase">{{ mainCategoryName }}</span>
          </h4>-->
          <h4 class="mb-0">Subcategories : {{ mainCategoryName }}</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>-->
              <!-- <vx-tooltip text="Export Excel">
                <a
                  :href="excelurl+`/v1/sub_category/excel/download?search=${searchQuery}`"
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>

              <vx-tooltip text="Export Archive Excel">
                <a
                  :href="excelurl+`/v1/sub_category/excel/download?archive=1&search=${searchQuery}`"
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>-->
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="subCatergoriesList"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "@/views/ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import SearchService from "@/services/searchService.js";
import subCategoryStatus from "./subCategoryStatus.vue";
import subcategoryArchive from "./subcategoryArchive";
import EditSubCategory from "./EditSubCategory.vue";
import { BASEURL } from "@/config/index.js";
const VxTour = () => import("@/components/VxTour.vue");
import CategoryService from "@/services/categoryService.js";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd
  },
  props: ["listType"],
  data() {
    return {
      excelurl: BASEURL,
      archive: this.listType,
      User: "Select User",
      form: {},
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      searchPayload : {},
      projectID: null,
      totalPages: 0,
      params: null,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null
      },
      configTodateTimePicker: {
        minDate: null
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" }
      ],
      columnDefs: [],
      contacts: contacts,
      mainCategoryName: this.$store.state.mainCategoryName,

      // Pratik
      subCatergoriesList: [],
      // mainCategoryName: "",
      categoryID: null
      // Pratik
    };
  },

  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getSubCategoryList(val);
          return val;
        } else {
          return val;
        }
        // this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  beforeMount() {
    const { categoryID } = this.$route.params;
    const { projectID } = this.$route.params;
    this.categoryID = categoryID;
    this.projectID = projectID
    eventBus.$on("refreshTablesub", e => {
      this.getSubCategoryList(this.currentPage);
    });
    eventBus.$once("refreshTableSubCategoryList", e => {
      this.getSubCategoryList(this.currentPage);
    });
    this.columnDefs = [
      {
        headerName: "Name",
        field: "category_name",
        width: 200,
        filter: false
        // checkboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // headerCheckboxSelection: true,
      },
      // {
      //   headerName: "Description",
      //   field: "sub_category_description",
      //   filter: false,
      //   width: 475
      // },
      {
        headerName: "Status",
        field: "category_id",
        filter: false,
        width: 100,
        hide: this.archive === "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: subCategoryStatus
        // cellRenderer: function(params) {
        //   return '<label class="switch"> <input type="checkbox" checked> <span class="slider round"></span> </label>';
        // }
      },
      {
        headerName: "Edit",
        field: "category_id",
        filter: false,
        width: 80,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EditSubCategory
        // cellRenderer: function(params) {
        //   return '<span class="text-center table-edit-icon"><i class="text-center material-icons">edit</i></span>';
        // }
      },

      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: this.archive ? "1" : "4",
        filter: false,
        width: this.archive ? 140 : 104,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: subcategoryArchive
      }
    ];
  },
  methods: {
    updateSearchQuery(val) {
      // this.gridApi.setQuickFilter(val);
        SearchService.getAllSerchResult(val, "category/singleDataByParent", this.searchPayload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.subCatergoriesList = data.data;
          } else {
            this.subCatergoriesList=[]
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getSubCategoryList: function(currentPage) {
      let filter = {
        categoryID: this.categoryID,
        page: currentPage,
        project_id: this.projectID
      };
      this.searchPayload = {
        categoryID: this.categoryID,
        project_id: this.projectID
      };

      if (this.archive == "archive") {
        filter["archive"] = true;
      }
      CategoryService.getAllSubCategories(filter)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.subCatergoriesList = data.data;
            // this.mainCategoryName = data.data[0].category_name;
            this.totalPages = data.pagination.last_page;

            this.gridApi.sizeColumnsToFit();
            this.$forceUpdate();
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
      // }
      // CategoryService.getAllCategories(filter)
      //   .then(response => {
      //     const { data } = response;
      //     if (data.err) {
      //     } else {
      // if (this.archive == "archive") {
      //   data.data = data.data.map(x => {
      //     x.category_active = 2;
      //     return x;
      //   });
      // }

      // this.subCatergoriesList = data.data.filter(
      //   x =>
      //     String(x.parent_id) !== "0" &&
      //     String(x.parent_id) === String(this.categoryID) &&
      //     String(x.category_active) !== "4"

      // );

      // this.subCatergoriesList = data.data.filter(x => {
      //   if (
      //     String(x.parent_id) !== "0" &&
      //     String(x.parent_id) === String(this.categoryID)
      //     // String(x.category_active) !== "4"
      //   )
      // return x;
      // });

      //   }
      // })
      // .catch(error => {
      //   console.log(error);
      // });
    }
  },
  mounted() {
    if (this.checkPermission(["sub_category", "GET"])) {
      this.getSubCategoryList(this.currentPage);
    }
    // let obj = JSON.parse(Vue.localStorage.get("CATDET"));
    this.gridApi = this.gridOptions.api;
  }
};
</script>
