<!-- =========================================================================================
   File Name: categoryView.vue
    Description: Category
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:  
    Author URL:   
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/categoryView">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>

      <!-- CATEGORY FORMS -->
      <!-- <div class="vx-col w-full mb-base">
        <sub-category-form v-if="checkPermission(['sub_category','POST'])"></sub-category-form>
      </div>-->

      <!-- CATEGORY LIST -->
      <vs-tabs v-if="checkPermission(['sub_category','GET'])">
        <vs-tab label="Subcategory List" icon-pack="feather" icon="icon-box">
          <div class="tab-text">
            <!-- CATEGORY LIST -->
            <div>
              <sub-category-list listType="noarchive"></sub-category-list>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Archive List" icon-pack="feather" icon="icon-box">
          <!-- CATEGORY ARCHIVE LIST -->
          <div>
            <sub-category-list listType="archive"></sub-category-list>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
// import subCategoryForm from "@/views/apps/category/subcategory/subCategoryForm.vue";
import subCategoryList from "@/views/apps/category/subcategory/subCategoryList.vue";
import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import categoryService from "@/services/categoryService.js";

export default {
  data() {
    return {
      isActive: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      categoryID: ""
    };
  },
  beforeMount: function() {
    this.categoryID = this.$route.params.categoryID;
    this.getSubCategories();
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    },
    getSubCategories: function() {
      // let payload = {
      //   categoryID: this.categoryID
      // };
      // categoryService
      //   .getSubCategoryByCategory(payload)
      //   .then(response => {
      //     const { data } = response;
      //     if (!data.error) {
      //       this.subCategoryList = data.data;
      //     } else {
      //     }
      //   })
      //   .catch(error => {
      //     console.log("error :", error);
      //   });
    }
  },
  components: {
    // subCategoryForm,
    subCategoryList
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
